@use 'styles/base/mixins';

@keyframes player-show-mobile {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: 180px;
    opacity: 1;
  }
}

@keyframes player-show-desktop {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: 515px;
    opacity: 1;
  }
}

.root {
  margin: 0 calc(var(--spacing-06) * -1) var(--spacing-06) calc(var(--spacing-06) * -1);
  padding: var(--spacing-06) 0 var(--spacing-06) var(--spacing-06);
  color: var(--white);
  background-color: var(--black);

  @include mixins.breakpoint(s) {
    margin: 0 0 var(--spacing-08);
  }

  @include mixins.breakpoint(l) {
    margin: 0 0 var(--spacing-08);
    padding: var(--spacing-08);
  }
}

.video {
  margin: 0 var(--spacing-06) var(--spacing-06) 0;
  animation-name: player-show-mobile;
  animation-duration: 1s;

  @include mixins.breakpoint(l) {
    margin: 0 0 var(--spacing-08) 0;
    animation-name: player-show-desktop;
  }
}

.meta {
  @include mixins.text-small;

  display: flex;

  > svg {
    margin-right: var(--spacing-02);
  }

  > span {
    margin: 0 var(--spacing-02);
  }
}
